import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './VideoTeaser.module.scss';
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight, faPlus, faCheck} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as mainStyle from "../../index.module.scss";
import {Link} from "gatsby";
import {useEffect, useState} from "react";
import {faPlay} from "@fortawesome/pro-light-svg-icons";

type VideoTeaserProps = {
    TeaserTitle1: string,
    TeaserDescription1: string,
    TeaserThumbnail1: string,
    TeaserVideo1: string,

    TeaserTitle2: string,
    TeaserDescription2: string,
    TeaserThumbnail2: string,
    TeaserVideo2: string,

    TeaserTitle3: string,
    TeaserDescription3: string,
    TeaserThumbnail3: string,
    TeaserVideo3: string,
}

function VideoTeaser(props: VideoTeaserProps) {

    const [currentVideo, setCurrentVideo] = useState({});
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (!currentVideo.Thumbnail) {
            setCurrentVideo({
                Thumbnail: props.TeaserThumbnail1,
                Video: props.TeaserVideo1,
            });
            setIsPlaying(true)
        }


    }, [])

    useEffect(() => {
        setCurrentVideo({
            Thumbnail: props.TeaserThumbnail1,
            Video: props.TeaserVideo1
        });
        setIsPlaying(false);
    }, [props])


    return <div className={style.elementWrapper}>
        <Container className={'py-2'}>
        <div className={style.subCaption + ' pt-5 text-md-start text-center'}>ALLES IN DEINER HAND</div>
        <h2 className={style.caption + ' text-md-start text-center'}>ÜBERSICHTLICHE OBERFLÄCHE</h2>
        <Row className={mainStyle.box + ' d-none d-sm-block'}>
            <Container>
                <Row className={'mb-5 ' + style.VideoArea}>

                    {currentVideo.Thumbnail && !isPlaying ?
                        <div className={style.imageWrapper}
                             onClick={currentVideo.Video ? () => setIsPlaying(true) : null}>
                            {currentVideo.Video ? <>
                                <div className={style.playButton}><FontAwesomeIcon
                                    icon={faPlay}/><br/>Hier klicken, um das Video abzuspielen
                                </div>
                            </> : null}
                            <img src={currentVideo.Thumbnail}
                                 alt={'thumbnail'}
                                 className={style.Thumbnail + (!currentVideo.Video ? (' ' + style.NoDarken) : ' ')}
                                 loading={"lazy"}
                            />
                        </div>
                        : null}
                    {currentVideo.Video && isPlaying ?
                        <video className={style.Thumbnail + ' ' + style.NoDarken} controls={true} autoPlay={true}>
                            <source src={currentVideo.Video} type={"video/mp4"}/>
                        </video>
                        : null}

                </Row>
                <Row>
                    <div className={'flex-row flex-wrap d-none d-sm-flex'} >
                        <Col lg={4} md={12} style={{paddingBottom: "25px"}} onClick={() => {
                            setCurrentVideo({
                                Thumbnail: props.TeaserThumbnail1,
                                Video: props.TeaserVideo1
                            });
                            setIsPlaying(false);
                        }}>
                            <Row>
                                <Col lg={2}>
                                    {currentVideo.Thumbnail === props.TeaserThumbnail1 ?
                                        <svg className={style.videoTeaserSVG} xmlns="http://www.w3.org/2000/svg" width="65" height="65"
                                             viewBox="0 0 65 65">
                                            <g transform="translate(-260 -2954)">
                                                <path
                                                    d="M0,0H50A15,15,0,0,1,65,15V65a0,0,0,0,1,0,0H15A15,15,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                                    transform="translate(260 2954)" fill="#bee000"/>
                                                <rect width="8" height="26" transform="translate(282 2974)"
                                                      fill="#fff"/>
                                                <rect width="8" height="26" transform="translate(298 2974)"
                                                      fill="#fff"/>
                                            </g>
                                        </svg> :
                                        <svg className={style.videoTeaserSVG}  xmlns="http://www.w3.org/2000/svg" width="65" height="65"
                                             viewBox="0 0 65 65">
                                            <g transform="translate(-748 -2994)">
                                                <path
                                                    d="M2,2V50A13,13,0,0,0,15,63H63V15A13,13,0,0,0,50,2H2M0,0H50A15,15,0,0,1,65,15V65H15A15,15,0,0,1,0,50Z"
                                                    transform="translate(748 2994)" fill="#bee000"/>
                                                <path d="M12,0,24,21H0Z" transform="translate(794 3016) rotate(90)"
                                                      fill="#bee000"/>
                                            </g>
                                        </svg>}
                                </Col>
                                <Col lg={7}>
                                    <h3 className={style.title}> {props.TeaserTitle1}</h3>
                                </Col>
                                <Col lg={3}></Col>
                            </Row>
                            <Row>
                                <Col lg={10}>
                                <span>{props.TeaserDescription1}</span>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={4} md={12} style={{paddingBottom: "25px"}} onClick={() => {
                            setCurrentVideo({
                                Thumbnail: props.TeaserThumbnail2,
                                Video: props.TeaserVideo2
                            });
                            setIsPlaying(false);
                        }}>
                            <Row>
                                <Col lg={2}>
                                    {currentVideo.Thumbnail === props.TeaserThumbnail2 ?
                                        <svg className={style.videoTeaserSVG}  xmlns="http://www.w3.org/2000/svg" width="65" height="65"
                                             viewBox="0 0 65 65">
                                            <g transform="translate(-260 -2954)">
                                                <path
                                                    d="M0,0H50A15,15,0,0,1,65,15V65a0,0,0,0,1,0,0H15A15,15,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                                    transform="translate(260 2954)" fill="#bee000"/>
                                                <rect width="8" height="26" transform="translate(282 2974)"
                                                      fill="#fff"/>
                                                <rect width="8" height="26" transform="translate(298 2974)"
                                                      fill="#fff"/>
                                            </g>
                                        </svg> :
                                        <svg className={style.videoTeaserSVG}  xmlns="http://www.w3.org/2000/svg" width="65" height="65"
                                             viewBox="0 0 65 65">
                                            <g transform="translate(-748 -2994)">
                                                <path
                                                    d="M2,2V50A13,13,0,0,0,15,63H63V15A13,13,0,0,0,50,2H2M0,0H50A15,15,0,0,1,65,15V65H15A15,15,0,0,1,0,50Z"
                                                    transform="translate(748 2994)" fill="#bee000"/>
                                                <path d="M12,0,24,21H0Z" transform="translate(794 3016) rotate(90)"
                                                      fill="#bee000"/>
                                            </g>
                                        </svg>}
                                </Col>
                                <Col lg={5}>
                                    <h3 className={style.title}> {props.TeaserTitle2}</h3>
                                </Col>
                                <Col lg={5}>

                                </Col>
                            </Row>
                            <Row>
                                <Col lg={10}>
                                <span> {props.TeaserDescription2}</span>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={4} md={12} style={{paddingBottom: "25px"}} onClick={() => {
                            setCurrentVideo({
                                Thumbnail: props.TeaserThumbnail3,
                                Video: props.TeaserVideo3
                            });
                            setIsPlaying(false);
                        }}>
                            <Row>
                                <Col lg={2}>
                                    {currentVideo.Thumbnail === props.TeaserThumbnail3 ?
                                        <svg className={style.videoTeaserSVG}  xmlns="http://www.w3.org/2000/svg" width="65" height="65"
                                             viewBox="0 0 65 65">
                                            <g transform="translate(-260 -2954)">
                                                <path
                                                    d="M0,0H50A15,15,0,0,1,65,15V65a0,0,0,0,1,0,0H15A15,15,0,0,1,0,50V0A0,0,0,0,1,0,0Z"
                                                    transform="translate(260 2954)" fill="#bee000"/>
                                                <rect width="8" height="26" transform="translate(282 2974)"
                                                      fill="#fff"/>
                                                <rect width="8" height="26" transform="translate(298 2974)"
                                                      fill="#fff"/>
                                            </g>
                                        </svg> :
                                        <svg className={style.videoTeaserSVG}  xmlns="http://www.w3.org/2000/svg" width="65" height="65"
                                             viewBox="0 0 65 65">
                                            <g transform="translate(-748 -2994)">
                                                <path
                                                    d="M2,2V50A13,13,0,0,0,15,63H63V15A13,13,0,0,0,50,2H2M0,0H50A15,15,0,0,1,65,15V65H15A15,15,0,0,1,0,50Z"
                                                    transform="translate(748 2994)" fill="#bee000"/>
                                                <path d="M12,0,24,21H0Z" transform="translate(794 3016) rotate(90)"
                                                      fill="#bee000"/>
                                            </g>
                                        </svg>}
                                </Col>
                                <Col lg={6}>
                                    <h3 className={style.title}>{props.TeaserTitle3}</h3>
                                </Col>
                                <Col lg={4}>

                                </Col>
                            </Row>
                            <Row>
                                <Col lg={10}>
                                <span>{props.TeaserDescription3}</span>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Row>
            </Container>
        </Row>
        <div className={'d-sm-none d-block'}>
            <div className={'mobile-video-teaser mb-5 mt-5'}>
                <img src={props.TeaserThumbnail1} className={style.MobileThumbnail + ' img-fluid'} alt={'thumb1'}/>
                <h3 className={style.mobileTitle}>{props.TeaserTitle1}</h3>
                <p className={'px-1'}>{props.TeaserDescription1}</p>

            </div>

            <div className={'mobile-video-teaser mb-5 mt-5'}>
                <img src={props.TeaserThumbnail2} className={style.MobileThumbnail + ' img-fluid'} alt={'thumb1'}/>
                <h3 className={style.mobileTitle}>{props.TeaserTitle2}</h3>
                <p className={'px-1'}>{props.TeaserDescription2}</p>

            </div>

            <div className={'mobile-video-teaser mb-5 mt-5'}>
                <img src={props.TeaserThumbnail3} className={style.MobileThumbnail + ' img-fluid'} alt={'thumb1'}/>
                <h3 className={style.mobileTitle}>{props.TeaserTitle3}</h3>
                <p className={'px-1'}>{props.TeaserDescription3}</p>

            </div>
        </div>
        </Container>
    </div>;
}

export default VideoTeaser;
