// extracted by mini-css-extract-plugin
export var MobileThumbnail = "VideoTeaser-module--MobileThumbnail--d7146";
export var NoDarken = "VideoTeaser-module--NoDarken--bi2zH";
export var Thumbnail = "VideoTeaser-module--Thumbnail--f7zV8";
export var VideoArea = "VideoTeaser-module--VideoArea--ZVQVM";
export var caption = "VideoTeaser-module--caption--LKbYb";
export var elementWrapper = "VideoTeaser-module--elementWrapper--KIDeu";
export var imageWrapper = "VideoTeaser-module--imageWrapper--+kf+R";
export var mobileTitle = "VideoTeaser-module--mobileTitle--ALI+T";
export var playButton = "VideoTeaser-module--playButton--P8+3Z";
export var subCaption = "VideoTeaser-module--subCaption--TtFjH";
export var title = "VideoTeaser-module--title--kD9tV";
export var videoTeaserSVG = "VideoTeaser-module--videoTeaserSVG--IgyLE";